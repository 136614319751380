import { Button, Drawer, Row, Space, Spin, notification } from "antd";
import apiHandler from "api";
import endpoint from "api/endpoint";
import CaseloadTabs from "domain/Caseload/CaseloadTabs/CaseloadTabs";
import TopSearch from "domain/Caseload/TopSearch/TopSearch";
import WizardProgressBar from "domain/EnrollParticipantWizard/WizardProgressBar";
import WizardStepContent from "domain/EnrollParticipantWizard/WizardStepContent";
import Filters from "domain/Filters/Filters";
import { setCaseloadData } from "features/caseload/caseLoadDataSlice";
import { setFilters } from "features/caseload/filterSlice";
import { setTopSearchQueryValue } from "features/caseload/searchSlice";
import loadProfile from "../../domain/Caseload/ParticipantProfile/ParticipantProfile";
import {
  setActiveParticipant,
  setButtonLoading,
  setDrawerErrorMessage,
  setEditParticipantProfile,
  setEnrollParticipantData,
  showEnrollParticipant,
  setHistoryDrawer,
  showLoader,
  setHistoryStartDate,
  setActiveHistoryTab
} from "features/common/commonSlice";
import {
  setWizardErrorObj,
  setWizardStep,
} from "features/enrollWizard/enrollWizardSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  StyleClosableItemList,
  StyledColLeft,
  StyledColRight,
  StyledHeading,
  StyleClosableItem,
} from "./Caseload.styles";
import { EnrollParticipant } from "../../api/enrollParticipant";
import HistoryDrawer from "domain/Caseload/History/HistoryDrawer";
import MessageDrawer from "domain/Caseload/SendMessage/MessageDrawer";
import { setParticipantProfileData } from "features/caseload/participantProfileDataslice";
import moment from "moment";
import { saveEnrollParticipantFormData } from "features/enrollParticipant/enrollParticipantSlice";
import SurvivorDrawer from "domain/Caseload/Survivor/SurvivorDrawer";

export const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45vh;
  font-size: 20px;
  font-weight: bold;
`;

const Caseload = () => {
  const { authToken } = useSelector((state) => state.login);

  const filters = useSelector((state) => state.filterData.filterDataObj);

  const selectedFilters = useSelector(
    (state) => state.caseloadFilters.filterObj
  );

  const caseloadFilters = useSelector(
    (state) => state.caseloadFilters.filterObj
  );

  const { topSearchQueryValue } = useSelector((state) => state.searchData);

  const isLoading = useSelector((state) => state.common.showLoader);

  const dispatch = useDispatch();

  const [closableItems, setClosableItems] = useState([]);
  const [activeId, setActiveId] = useState();
  const [callParticipant, setCallParticipant] = useState(false);
  const enrollParticipantVisible = useSelector(
    (state) => state.common.showEnrollParticipant
  );

  const { enrollParticipantFormData } = useSelector(
    (state) => state.enrollParticipant
  );

  let currentStep = useSelector(
    (state) => state.enrollWizardData.currentWizardStep
  );

  let newParticipantId;

  const {
    activeParticipantId,
    enrollParticipantData,
    showMessageDrawer,
    showHistoryDrawer,
    assignedDevices,
    buttonLoading,
    showSurvivorDrawer,
    userType
  } = useSelector((state) => state.common);

  let programData = useSelector((state) => state.enrollWizardData.programData);

  const [currentWizardStep, setCurrentWizardStep] = useState(currentStep);

  const postProgram = async (activate) => {
    dispatch(setButtonLoading(true));
    var tz = moment.tz.guess();
    var date = moment().minute(0).seconds(0).milliseconds(0).tz(tz);

    date = date.toISOString();
    date = date.replace(":00.000Z", "+0000Z");

    if (activate) {
      const programList = { ...programData, activationDate: date };
      const payload = {};
      if (enrollParticipantData && enrollParticipantData.status === 'INACTIVE') {
        payload['activationDate'] = date;
      } else {
        payload['deactivationDate'] = date;
      }
      await apiHandler({
        url:
          endpoint.ENROLL_PARTICIPANT +
          "/" +
          `${enrollParticipantData.id || activeParticipantId}` +
          "/program",
        method: "POST",
        data: payload,
        authToken: authToken,
      }).then(async (result) => {
        await apiHandler({
          url:
            endpoint.ENROLL_PARTICIPANT +
            "/" +
            `${enrollParticipantData.id || activeParticipantId}` +
            "/program",
          method: "POST",
          data: programList,
          authToken: authToken,
        }).then((result) => {
          dispatch(setEditParticipantProfile(false));
          dispatch(setParticipantProfileData([]));
          dispatch(setEnrollParticipantData([]));
          dispatch(saveEnrollParticipantFormData([]));
          dispatch(setDrawerErrorMessage());
          dispatch(setWizardErrorObj());
          dispatch(showEnrollParticipant(false));
          dispatch(setButtonLoading(false));
          notification.info({
            description: result.data.message,
            placement: "topRight",
            duration: 5,
          });
          if (enrollParticipantData.id) {
            newParticipantId = enrollParticipantData.id;
            loadcaseloadData();
          }
          
        //  loadProfile()
        });
      });
    } else {
      await apiHandler({
        url:
          endpoint.ENROLL_PARTICIPANT +
          "/" +
          `${enrollParticipantData.id || activeParticipantId}` +
          "/program",
        method: "POST",
        data: programData,
        authToken: authToken,
      }).then((result) => {
        dispatch(setEditParticipantProfile(false));
        dispatch(setParticipantProfileData([]));
        dispatch(setEnrollParticipantData([]));
        dispatch(saveEnrollParticipantFormData([]));
        dispatch(setDrawerErrorMessage());
        dispatch(setWizardErrorObj());
        dispatch(showEnrollParticipant(false));
        dispatch(setButtonLoading(false));
        notification.info({
          description: result.data.message,
          placement: "topRight",
          duration: 5,
        });
        if (enrollParticipantData.id) {
          newParticipantId = enrollParticipantData.id;
          loadcaseloadData();
        }
      });
    }
  };

  const onClose = () => {
    dispatch(showEnrollParticipant(false));
    dispatch(setEnrollParticipantData([]));
    dispatch(saveEnrollParticipantFormData([]));
    dispatch(setDrawerErrorMessage());
    dispatch(setButtonLoading(false));
    if (callParticipant) {
      const tempParticipant = activeParticipantId;
      dispatch(setActiveParticipant(0));
      setTimeout(()=>{
        dispatch(setActiveParticipant(tempParticipant));
      },100);
      setCallParticipant(false);
    }
  };

  const setFilter = (value, filterType) => {
    dispatch(showLoader(true));

    if (filterType === undefined) {
      filterType = "agencyId";
    }

    let filtersList = caseloadFilters;

    if (filterType === "search") {
      dispatch(setTopSearchQueryValue(""));
      let closeItems = closableItems.filter(
        (obj) => obj.filterType !== "search" && obj
      );
      setClosableItems(closeItems);
      dispatch(showLoader(false));
      return;
    }

    if (filtersList[filterType]?.includes(value)) {
      filtersList = filters[filterType]?.map((item) => {
        return (
          filtersList &&
          filtersList[filterType]?.filter(
            (val) => item.parentAgencyId !== val && val
          )
        );
      });
      filtersList =
        filtersList &&
        filtersList[filterType]?.filter((val) => val !== value && val);
      dispatch(setFilters({ ...caseloadFilters, [filterType]: filtersList }));
    }
  };
  const { id,startDate,endDate,type } = useParams();
  const filterKeys = [
    "agencyId",
    "status",
    "solution",
    "eventTypeId",
    "participantType",
    "search",
  ];

  if (
    topSearchQueryValue !== "" &&
    topSearchQueryValue !== null &&
    topSearchQueryValue !== undefined
  ) {
    closableItems.push({
      value: topSearchQueryValue,
      label: topSearchQueryValue,
      filterType: "search",
    });
  }

  useEffect(() => {
    
    let localitem = localStorage.getItem('userType')
    console.log("userTypedddddddd", activeParticipantId);
    let queryParams = "?";
    filterKeys?.map((key) => {
      selectedFilters &&
        selectedFilters[key]?.map((filterObj) => {
          if (key !== "status") {
            queryParams += key + "=" + filterObj + "&";
          }
        });
    });

    apiHandler({
      url: `${endpoint.CASELOAD}/${
        (selectedFilters &&
          selectedFilters["status"] &&
          selectedFilters["status"][0]) ||
        "active"
      }${queryParams}`,
      authToken,
    }).then(async (result) => {
      let activeparticipantId = null
      // if (!activeParticipantId) {
        dispatch(setCaseloadData(result?.data));
        await Promise.all(result?.data.map(async (element)=>{
          if(element.id == id){
            console.log("work hereeeee innner id in",element.id);
            activeparticipantId = element.id
            dispatch(setActiveParticipant(activeId));
            //setActiveId(element.id);
            

          }
         
      }))
        
       // console.log("work here in active id",activeId);
        if(activeparticipantId && !startDate && !endDate && !type){
          console.log("work here in active id");
          dispatch(setActiveParticipant(activeparticipantId));
        }else if(activeparticipantId && startDate && endDate && type ){
          console.log("work here lllll");
           dispatch(setActiveParticipant(activeparticipantId));
           dispatch(setHistoryDrawer(true));
          dispatch(setHistoryStartDate(moment(startDate)))
          if(type =="metrics"){
            dispatch(setActiveHistoryTab("metrics"));
          }
          if(type =="breath"){
            dispatch(setActiveHistoryTab("breathalyzer"));
          }
          if(type =="gps"){
            dispatch(setActiveHistoryTab("gpshistory"));
          }
          
        }else{
          dispatch(setActiveParticipant(result?.data[0]?.id));
        }
      //dispatch(setActiveParticipant(result?.data[0]?.id));
      // }
      dispatch(showLoader(false));
    });
    let closableItemsArr = [];
    console.log("filterKeys",filterKeys);
    filterKeys.map((key) => {
      return (
        filters &&
        filters[key]?.map(
          (filterObj) =>
            selectedFilters &&
            selectedFilters[key]?.filter((selectedFilterValue) => {
              if (
                selectedFilterValue === filterObj.value ||
                selectedFilterValue === filterObj.id
              ) {
                closableItemsArr = [...closableItemsArr, filterObj];
              }
            })
        )
      );
    });
    setClosableItems(closableItemsArr);
  }, [selectedFilters,activeId]);

  /* useEffect(() => {
    if (newParticipantId) {
      loadcaseloadData();
    }
  }, [newParticipantId]); */
  const loadcaseloadData = () => {
    dispatch(showLoader(true));
    let localitem = localStorage.getItem('userType')
    console.log("userTypedddddddd", activeParticipantId);
    let queryParams = "?";
    filterKeys?.map((key) => {
      selectedFilters &&
        selectedFilters[key]?.map((filterObj) => {
          if (key !== "status") {
            queryParams += key + "=" + filterObj + "&";
          }
        });
    });
    apiHandler({
      url: `${endpoint.CASELOAD}/${
        (selectedFilters &&
          selectedFilters["status"] &&
          selectedFilters["status"][0]) ||
        "active"
      }${queryParams}`,
      authToken,
    }).then(async (result) => {
      let activeparticipantId = null
      // if (!activeParticipantId) {
        dispatch(setCaseloadData(result?.data));
        await Promise.all(result?.data.map(async (element)=>{
          if(element.id == newParticipantId){
            console.log("work hereeeee innner id in",element.id);
            activeparticipantId = element.id
            dispatch(setActiveParticipant(newParticipantId));
            //setActiveId(element.id);
            

          }
         
      }))
        
       // console.log("work here in active id",activeId);
       if (newParticipantId&&newParticipantId !== activeParticipantId) {
        activeparticipantId = newParticipantId;
        newParticipantId = null;
       }
        
        if(activeparticipantId && !startDate && !endDate && !type){
          console.log("work here in active id");
          dispatch(setActiveParticipant(activeparticipantId));
        }else if(activeparticipantId && startDate && endDate && type ){
          console.log("work here lllll");
           dispatch(setActiveParticipant(activeparticipantId));
           dispatch(setHistoryDrawer(true));
          dispatch(setHistoryStartDate(moment(startDate)))
          if(type =="metrics"){
            dispatch(setActiveHistoryTab("metrics"));
          }
          if(type =="breath"){
            dispatch(setActiveHistoryTab("breathalyzer"));
          }
          if(type =="gps"){
            dispatch(setActiveHistoryTab("gpshistory"));
          }
          
        }else{
          dispatch(setActiveParticipant(result?.data[0]?.id));
        }
      //dispatch(setActiveParticipant(result?.data[0]?.id));
      // }
      dispatch(showLoader(false));
    });
  }

  useEffect(() => {
    dispatch(setWizardStep(currentWizardStep));
  }, [currentWizardStep]);

  useEffect(() => {
    setCurrentWizardStep(currentStep);
  }, [currentStep]);

  return (
    <Row>
      <StyledColLeft md={4}>
        <Filters />
      </StyledColLeft>
      <StyledColRight md={20}>
        <StyledHeading>Caseload</StyledHeading>
        <hr />
        <TopSearch />
        <Row>
          <StyleClosableItemList>
            {closableItems
              .filter(
                (v, i, a) =>
                  a.findIndex(
                    (t) => t.label === v.label && t.value === v.value
                  ) === i
              )
              ?.map(({ value, label, name, filterType, id }) => {
                return (
                  <StyleClosableItem>
                    {label || name}{" "}
                    <span onClick={() => setFilter(value || id, filterType)}>
                      x
                    </span>
                  </StyleClosableItem>
                );
              })}
          </StyleClosableItemList>
        </Row>

        {isLoading ? (
          <StyledSpace>
            <Spin size={"large"} />
            Loading...
          </StyledSpace>
        ) : (
          activeParticipantId && <CaseloadTabs />
        )}

        <Drawer
          title="Enrollment Wizard"
          placement="right"
          size="large"
          destroyOnClose
          onClose={onClose}
          visible={enrollParticipantVisible}
        >
          <div className="container">
            <WizardProgressBar />
            <WizardStepContent currentWizardStep={currentWizardStep} />
          </div>
          {enrollParticipantVisible && (
            <div
              style={{
                position: "fixed",
                width: "100%",
                padding: 10,
                display: "flex",
                justifyContent: "flex-end",
                bottom: 0,
                borderTop: "1px solid #c5c5c5",
                left: 0,
                background: "#fff",
              }}
              className="container"
            >
              <Space>
                {currentWizardStep >= 0 && currentWizardStep <= 2 && (
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => {
                      onClose();
                      setCurrentWizardStep(0);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {currentWizardStep === 2 && (
                  <>
                    <Button
                      type="primary"
                      disabled={!programData || assignedDevices?.length === 0}
                      onClick={() => postProgram(false)}
                    >
                      Save for Later
                    </Button>
                    {userType === 'new' || enrollParticipantData.status === 'INACTIVE' ? (
                      <Button
                      loading={buttonLoading}
                      onClick={() => postProgram(true)}
                      disabled={!programData || assignedDevices?.length === 0}
                      type="primary"
                    >
                      Activate
                    </Button>
                    ):(
                      <Button
                      loading={buttonLoading}
                      onClick={() => postProgram(true)}
                      
                      disabled
                    >
                      Activate
                    </Button>
                    )}
                    
                  </>
                )}
                {currentWizardStep < 2 && (
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    onClick={async () => {
                      dispatch(setDrawerErrorMessage(""));
                      if (currentWizardStep === 0) {
                        await EnrollParticipant(
                          enrollParticipantFormData,
                          authToken,
                          dispatch,
                          setCurrentWizardStep,
                          currentWizardStep
                        );
                        setCallParticipant(true);
                      } else {
                        setCurrentWizardStep(currentWizardStep + 1);
                      }
                    }}
                    // disabled={(currentStep === 0 && !stepOneComplete) && true}
                  >
                    Save & Continue
                  </Button>
                )}
              </Space>
            </div>
          )}
        </Drawer>

        {showHistoryDrawer && <HistoryDrawer />}
        {showMessageDrawer && <MessageDrawer />}
        {showSurvivorDrawer && <SurvivorDrawer />}
      </StyledColRight>
    </Row>
  );
};

export default Caseload;
